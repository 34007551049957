import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Typography, useMediaQuery } from '@mui/material';
import mediaQueries from '../styles/mediaQueries';
import TaskLoaderNew from '../components/TaskLoaderNew';
import {backgroundImage, rightArrow, certificatePersonImg} from '../styles/staticImages'
import { getCourseCategories } from '../services/api';

const CourseCategoryPage = ({generalSelection,educationSelection,setDomainSelection,gotoPrevious, gotonext}) => {


  const [courseCategories, setCourseCategories] = useState([]);
  const [selectedCourse ,setSelectedCourse] = useState('');


  useEffect(() => {
    const fetchCourseLevel = async () => {
        try {
            const data = await getCourseCategories(generalSelection,educationSelection);
            setCourseCategories(data.data.category_list);
            
        } catch (err) {
            console.log(err.message);
        }
    };
    fetchCourseLevel();
}, [generalSelection, educationSelection]);

const handleOnClick = (domainValue) =>{
   setSelectedCourse(domainValue)
   setDomainSelection(domainValue)
   
}


  const isMobile = useMediaQuery(mediaQueries.xs); // Mobile breakpoint
  


  return (
    <>
            <Box sx={{ mt: 2, display:'flex' ,flexDirection: 'column', height:'80vh', position: isMobile ? "static": "relative" }}>
                  <TaskLoaderNew isMobile={isMobile} showBackButton={true} gotoPrevious={gotoPrevious} stepValue={"domain"}/>
                    <Box sx={{ pl: { xs: 2, md: 10 },marginTop: isMobile ? '15px': '5px', marginLeft: '5px', justifyContent: 'left' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', color: 'rgba(78, 78, 78, 1)' }}>
                                    <Typography
                                            sx={{
                                                fontSize: isMobile ? '20px' : '32px',
                                                lineHeight: isMobile ?'30px': '48px',
                                                fontWeight: '600',
                                            }}
                                            gutterBottom
                                        >
                                             Find your course and get{' '} 
                                             <Typography
                                                component="span"
                                                sx={{
                                                    fontSize: isMobile ? '20px' : '32px',
                                                    lineHeight: isMobile ?'30px': '48px',
                                                    fontWeight: '600',
                                                    color: 'rgba(17, 58, 130, 1)',
                                                }}
                                            >
                                              {isMobile && (<br/>)}certified
                                            </Typography>!
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontSize: { xs: '16px', md: '20px' },
                                                lineHeight: '30px',
                                                fontWeight: '400',
                                            }}
                                            gutterBottom
                                        >
                                            Choose Course Category:
                                        </Typography>
                                    </Box>
                      </Box>
                      <Box sx={{ width :'100%',display:'flex', height: "50vh"}}>
                        <Box   sx={{
                                      zIndex: 10,
                                      width: isMobile ? '100%' : '60%',
                                      // maxHeight: "550px",
                                      height: isMobile ? '77%' : '89%',
                                      ml: isMobile ? '' : '65px',
                                      marginTop: '20px',
                                      marginRight: isMobile ? '15px' : '',
                                      border: '2px solid #ccc',
                                      background: "white",
                                      borderRadius: '16px',
                                      paddingBottom: "20px",
                                      overflowY: 'auto', // Enables vertical scrolling
                                      overflowX: 'hidden', // Prevents horizontal scrolling
                                      scrollbarWidth: 'thin', // For modern browsers
                                      '&::-webkit-scrollbar': { // For WebKit-based browsers
                                        width: '6px',
                                      },
                                      '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: '#ccc',
                                        borderRadius: '4px',
                                      },
                                      '&::-webkit-scrollbar-thumb:hover': {
                                        backgroundColor: '#aaa',
                                      },
                                    }}>  
                              {courseCategories.map((category, index) => (
                                  <React.Fragment key={index}>
                                <Box
                                  key={index}
                                  onClick={() => handleOnClick(category)} 
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '15px 30px',
                                    cursor: 'pointer',
                                    backgroundColor: selectedCourse === category 
                                                    ? 'rgba(222, 230, 253, 1)' // Set background color when selected
                                                    : 'transparent', // Transparent when not selected
                                    transition: 'background-color 0.3s',
                                    '&:hover': {
                                      backgroundColor: '#f9f9f9',
                                    },
                                  }}
                                >
                                     <Typography
                                      variant="body1"
                                      fontSize="16px"
                                      sx={{
                                        fontWeight: '400',
                                        color:'rgba(136, 136, 136, 1)',
                                      }}
                                    >
                                      {category.replace(/_/g, " ")}
                                    </Typography>
                                    <img src={rightArrow} alt='right-arrow'/>
                                    </Box>
                                    {index < courseCategories.length - 1 && <Divider sx={{ backgroundColor: '#ccc', width :  'calc(100% - 10%)' ,marginLeft :isMobile?'':'20px', marginRight : isMobile ? '':'50px'}} />}
                                  </React.Fragment>
                               ))}
                        </Box>


{isMobile && (<Box
                         sx={{
                           display: "flex",
                           justifyContent: "flex-end",
                           position: "absolute",
                           bottom:  "40px", // Adjusted to position near the bottom
                           right: "25px", // Adjusted to position near the right edge
                           zIndex: 10, // Ensure button is on top of all other elements
                         }}
                     >
                         <Button
                             variant="contained"
                             onClick={() => gotonext('search_courses')}//gotonext('course')}
                             disabled={selectedCourse === ''}
                             sx={{
                                 borderRadius: '16px',
                                 width: isMobile? '156px' : { xs: '120px', md: '150px' },
                                 height: isMobile? '56px' : { xs: '50px', md: '70px' },
                                 fontSize: { xs: '18px', md: '24px' },
                                 fontWeight: '600',
                                 lineHeight: '36px',
                                 backgroundColor: 'rgba(17, 58, 130, 1)',
                                 color: '#FFF',
                                 '&.Mui-disabled': {
                                      backgroundColor: '#C6C6C6',
                                  }
                             }}
                         >
                             Next
                         </Button>
                     </Box>
                      )}
                      </Box>

              {!isMobile && (
                <>
                <Box sx={{
                  position: "absolute",
                  right: "0px",
                  bottom: "0px",
                  width: "40vw",
                  height: "60vh",
                  backgroundImage: `url(${backgroundImage})`,
                  backgroundSize: '100%', // Adjust the size of the background image
                  backgroundRepeat: 'no-repeat', // Prevent the image from repeating
                  backgroundPosition: 'top left', // Center the image
                  zIndex: 0,
                  opacity: 1, }}>
                  
                  </Box>
                  <Box 
                    sx={{  
                      position: "absolute",
                      right: "0px",
                      bottom: "0px",
                      zIndex: 1,
                      height: '50vh',
                      display: 'flex',// Makes the Box a flex container
                      justifyContent: 'center', // Centers content horizontally
                      alignItems: 'flex-end', // Centers content vertically
                    }}>
                    <img style={{
                                width: '100%',
                                height: '100%',
                                right:'10px',
                                pointerEvents: 'none',
                                userSelect: 'none'
                        }} 
                          src={certificatePersonImg}
                          alt="Level Character" />

                  

                  </Box>
                  <Button
                    variant="contained"
                    onClick={() => gotonext('search_courses')}
                    disabled={selectedCourse === ''}
                    sx={{
                        zIndex: 2,
                        position: "absolute",
                        right: "50px",
                        bottom: "20px",
                        borderRadius: '16px',
                        width: isMobile? '156px' : { xs: '120px', md: '150px' },
                        height: isMobile? '56px' : { xs: '50px', md: '8vh' },
                        fontSize: { xs: '18px', md: '24px' },
                        fontWeight: '600',
                        lineHeight: '36px',
                        backgroundColor: 'rgba(17, 58, 130, 1)',
                        color: '#FFF',
                        '&.Mui-disabled': {
                            backgroundColor: '#C6C6C6',
                        }
                    }}
                >
                    Next
                </Button>
              </>
              )} 
            </Box>
            
        </>

  )
}

export default CourseCategoryPage
