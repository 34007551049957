import React, { useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import mediaQueries from '../styles/mediaQueries';
import TaskLoaderNew from '../components/TaskLoaderNew';
import EachLevel from '../components/EachLevel';
import {graduationHat , certification, continueEducation, backgroundImage, levelCharacter} from '../styles/staticImages'

const LevelPage = ({ gotonext,gotoPrevious, generalSelection, setEducationSelection}) => {

  const [selectedLevel, setSelectedLevel] = useState('');

  const valueToImgMapping = {
    'Undergraduate': graduationHat, 'Postgraduate': graduationHat, 'Diploma': certification, 'Certificate': certification, 'Continuing Education': continueEducation
  }

  const levels1 = [
    'Undergraduate',
    'Postgraduate',
   
  ];

  const levels2 = [
  'Diploma',
  'Certificate'
  ];

  const levels3 = ['Continuing Education']

  const mobileLevels = ['Undergraduate', 'Postgraduate', 'Diploma', 'Certificate', 'Continuing Education']


  const handleRadioChange = (event) => {
    setEducationSelection(event.target.value)
    setSelectedLevel(event.target.value); // Update selected level
  };

  function handleClick(value){
    // const valueToKeyMapping = {
    //   "Undergraduate Level": "Undergraduate", "Postgraduate Level": "Postgraduate", "Diploma Level": "Diploma", "Certificate Level": "Certificate", "School Level": "School", "Continuing Education": "Continuing Education"
    // }  
    setSelectedLevel(value)
    setEducationSelection(value)
  }


  const isMobile = useMediaQuery(mediaQueries.xs); // Mobile breakpoint


  return (
    <>
    <div style={{height: "80vh", position: isMobile ? "static": "relative"}}>
            <Box sx={{ mt: 2, display:'flex' ,flexDirection: 'column', height:'100%' }}>
                  <TaskLoaderNew isMobile={isMobile} showBackButton={true} gotoPrevious={gotoPrevious} stepValue={"education"}/>
                    <Box sx={{ pl: { xs: 2, md: 10 },marginTop: isMobile ? '15px': '5px', marginLeft: '5px', justifyContent: 'left' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', color: 'rgba(78, 78, 78, 1)' }}>
                                        <Typography
                                            sx={{
                                                fontSize: isMobile ? '20px' : '32px',
                                                lineHeight: isMobile ?'30px': '48px',
                                                fontWeight: '600',
                                            }}
                                            gutterBottom
                                        >
                                            Up-skill with{' '} 
                                            <Typography
                                                component="span"
                                                sx={{
                                                    fontSize: isMobile ? '20px' : '32px',
                                                    lineHeight: isMobile ?'30px': '48px',
                                                    fontWeight: '600',
                                                    color: 'rgba(17, 58, 130, 1)',
                                                }}
                                            >
                                                specialised {isMobile && (<br/>)} courses
                                            </Typography>{' '}
                                            from top-tier colleges!
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontSize: isMobile ? '16px' : '20px',
                                                lineHeight: isMobile? '24px': '30px',
                                                fontWeight: '400',
                                            }}
                                            gutterBottom
                                        >
                                            Choose the Level of Course:
                                        </Typography>
                                    </Box>
                      </Box>
                      <Box sx={{ width : isMobile ? 'calc(100% - 14px)' :'100%',display:'flex'}}>
                      {!isMobile && (
                     <Box sx={{width: "70%" ,ml:  isMobile ? '0px' : '65px'}}>  
                        <Box sx={{display:'flex', flexWrap:'wrap' , alignItems: 'center', gap:5}}>

                          <Box>
                              <img src={graduationHat} alt='Graduation-Hat'/>
                          </Box>

                            <Box sx={{display: 'flex',gap: 2 }}>
                            {
                            levels1.map((item)=>( 
                                <EachLevel 
                                  mobile={isMobile}
                                  btnValue={item}
                                  btnImg={item}
                                  setSelectedLevel = {handleClick}
                                  selectedValue={selectedLevel} 
                                  onChange={handleRadioChange}
                                  />
                            ))
                          }  
                            </Box>
                        </Box>

                        <Box sx={{display:'flex', flexWrap:'wrap', marginTop:'35px', alignItems: 'center', gap:5}}>
                                <Box>
                                    <img src={certification} alt='Graduation-Hat'/>
                                </Box>

                                  <Box sx={{display: 'flex', gap: 2}}>
                                  {
                                  levels2.map((item)=>( 
                                    <EachLevel 
                                      mobile={isMobile}
                                      btnValue={item}
                                      btnImg={item}
                                      setSelectedLevel = {handleClick}
                                      selectedValue={selectedLevel} 
                                      onChange={handleRadioChange}
                                      />
                                  ))
                                }  
                                  </Box>

                        </Box>

                        <Box sx={{display:'flex', flexWrap:'wrap', marginTop:'35px', alignItems: 'center', gap:5}}>

                                <Box>
                                    <img src={continueEducation} alt='Graduation-Hat'/>
                                </Box>

                                  <Box sx={{display: 'flex',marginLeft:'35px', gap: 2}}>
                                  {
                                  levels3.map((item)=>( 
                                        <EachLevel 
                                          mobile={isMobile}
                                          btnValue={item}
                                          btnImg={item}
                                          setSelectedLevel = {handleClick}
                                          selectedValue={selectedLevel} 
                                          onChange={handleRadioChange}
                                          />
                                  ))
                                }  
                                  </Box>

                          </Box>

                  </Box>
                      )}

                      {isMobile && (
                        <Box sx={{ width: '100%' ,height: '100%',display:'flex', flexDirection:'column'}}>  
                              {mobileLevels.map((item) => (
                                <Box sx={{marginBottom : '30px'}} key={item}>
                                    <EachLevel
                                      key={item}
                                      mobile={isMobile}
                                      btnValue={item}
                                      btnImg={valueToImgMapping[item]}
                                      setSelectedLevel = {handleClick}
                                      selectedValue={selectedLevel} // Pass the selected value
                                      onChange={handleRadioChange} // Pass change handler
                                    />
                                    </Box>
                                  ))}

                        </Box>
                      )}



                 {!isMobile } 
                      {isMobile && (<Box
                         sx={{
                           display: "flex",
                           justifyContent: "flex-end",
                           position: "absolute",
                           bottom:  "40px", // Adjusted to position near the bottom
                           right: "25px", // Adjusted to position near the right edge
                           zIndex: 10, // Ensure button is on top of all other elements
                         }}
                     >
                     <Button
                             variant="contained"
                             onClick={() => gotonext('course')}//gotonext('course')}
                             disabled={selectedLevel === ''}
                             sx={{
                                 borderRadius: '16px',
                                 width: isMobile? '156px' : { xs: '120px', md: '150px' },
                                 height: isMobile? '56px' : { xs: '50px', md: '70px' },
                                 fontSize: { xs: '18px', md: '24px' },
                                 fontWeight: '600',
                                 lineHeight: '36px',
                                 backgroundColor: 'rgba(17, 58, 130, 1)',
                                 color: '#FFF',
                                 '&.Mui-disabled': {
                                      backgroundColor: '#C6C6C6',
                                  }
                             }}
                         >
                             Next
                         </Button>
                         
                     </Box>
                      )}
                      </Box>
            </Box>
            {
              !isMobile &&
            <>
            <Box sx={{
              position: "absolute",
              right: "0px",
              bottom: "0px",
              width: "50vh",
              height: "60vh",
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: "cover", // Adjust the size of the background image
              backgroundRepeat: "no-repeat", // Prevent the image from repeating
              zIndex: 0, // Set to 0 to avoid interfering with the button
              opacity: 1,
                  }}
                    >

            </Box>
            <Box 
              sx={{
                position: "absolute",
                right: "0px",
                bottom: "0px",
                zIndex: 1, // Ensure this container is below the button
                height: "50vh",
                display: "flex", // Makes the Box a flex container
                justifyContent: "flex-end", // Centers content horizontally
                alignItems: "flex-end", // Centers content vertically
              }}>
              <img style={{
                          width: '100%',
                          height: '100%',
                          pointerEvents: 'none',
                          userSelect: 'none'
                  }} 
                    src={levelCharacter}
                    alt="Level Character"
                    />

            {/* Next Button */}
            <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  position: "absolute",
                  bottom: "40px", // Adjusted to position near the bottom
                  right: "80px", // Adjusted to position near the right edge
                  zIndex: 10, // Ensure button is on top of all other elements
                }}
            >
            </Box>

            </Box>
            <Button
                variant="contained"
                onClick={() => gotonext('course')}//gotonext('course')}
                disabled={selectedLevel === ''}
                sx={{
                    zIndex: 2,
                    position: "absolute",
                    right: "50px",
                    bottom: "20px",
                    borderRadius: '16px',
                    width: isMobile? '156px' : { xs: '120px', md: '150px' },
                    height: isMobile? '56px' : { xs: '50px', md: '8vh' },
                    fontSize: { xs: '18px', md: '24px' },
                    fontWeight: '600',
                    lineHeight: '36px',
                    backgroundColor: 'rgba(17, 58, 130, 1)',
                    color: '#FFF',
                    '&.Mui-disabled': {
                        backgroundColor: '#C6C6C6',
                    }
                }}
            >
                Next
            </Button>
            </>
            }
        </div>
        </>

  )
}

export default LevelPage
