import { Box, Typography, Radio} from '@mui/material'
import React from 'react'

const EachLevel = ({mobile,btnValue,btnImg,setSelectedLevel, selectedValue, onChange}) => {

  return (
    <Box sx={{
      paddingX: '30px',
      height: '57px',
      display: 'flex', // Enable flexbox
      alignItems: 'center', // Vertically center content
      justifyContent: 'center',
      borderRadius: '20px',
      margin: '1px',
      backgroundColor: selectedValue === btnValue ? 'rgba(222, 230, 253, 1)' : '',
      border: selectedValue === btnValue
      ? '2px solid rgba(17, 58, 130, 1)' // Border color when selected
      : '2px solid rgba(209, 212, 226, 1)',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      '&:hover': {
        boxShadow: 0,
        transform: 'translateY(-2px)',
      },
    }}
    onClick={() => setSelectedLevel(btnValue)}
    >

{mobile && (
  <>
        <Radio
          value={btnValue} 
          checked={selectedValue === btnValue} 
          onChange={onChange}
          sx={{
            display: 'flex', 
            justifyContent: 'center', 
            padding: 0, // Removes any unwanted padding
            backgroundColor: 'transparent', // Set transparent background if needed
          }}
       />
       <Box
       sx={{
         display: 'flex',
         flexDirection: 'row',
         alignItems: 'center',
         justifyContent: 'center', // Ensure items are centered horizontally
         width: '100%',// Background color of the button area
         padding: '8px', // Adds padding to the button
         borderRadius: '4px', // Optional: Add rounded corners if desired
       }}
     >
       {/* Text (Button Label) centered */}
       <Typography sx={{ flex: 1, textAlign: 'center', fontSize: "18px", fontWeight: "400", color: "#4E4E4E", fontFamily: "'Poppins', sans-serif"}}>
         {btnValue}
       </Typography>
       {/* Image aligned to the right */}
       {btnImg && (
         <img
           src={btnImg}
           alt={btnValue}
           style={{ width: '24px', height: '24px', marginLeft: '8px' }} // Adds spacing between text and image
         />
       )}
     </Box>
     </>  
      )}
   {!mobile && (
    <Typography style={{fontSize: "18px", fontWeight: "400", color: "#4E4E4E", fontFamily: "'Poppins', sans-serif"}}>{btnValue}</Typography>
   )}


</Box>

    
  )
}

export default EachLevel
