
import React from 'react';
import BackButton from './PreviousBtn';
import {generalTask, educationTask, domainTask, coursesTask} from '../styles/staticImages'
import PropTypes from 'prop-types';
import { Box, Typography, Divider } from '@mui/material';

const taskImages = {
  general: generalTask,
  education: educationTask,
  domain: domainTask,
  courses: coursesTask,
};

const MobileView = ({ gotoPrevious, showBackButton}) => (
  <>
  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "center", height: "50px" }}>
        {
          showBackButton && 
          <Box sx={{}}>
            <BackButton isMobile={true} onClick={gotoPrevious} aria-label="Close task loader" />
          </Box>
        }
        <Typography color="rgba(122, 122, 122, 1)" fontSize="16px" fontWeight={600} paddingLeft={2} style={{fontFamily: "Poppins"}}>
          Course Recommendation
        </Typography>
  </Box>
  <Divider style={{ width: '100%', opacity: '0.2' }} color="rgba(222, 228, 255, 1)" orientation="horizontal" />
  </>
);

const DesktopView = ({ stepValue ,gotoPrevious, showBackButton}) => (
  <Box sx={{ display: 'flex', mb: 3, ml: 10}}>
    
    { showBackButton && <BackButton isMobile={false} onClick={gotoPrevious}/>}

    {taskImages[stepValue] && (
      <img
        src={taskImages[stepValue]}
        alt={`${stepValue} Task Loader`}
        style={{ margin: '0 auto', width: '100%', maxWidth: '600px' }}
      />
    )}
  </Box>
);

const TaskLoaderNew = ({ isMobile, showBackButton, stepValue, gotoPrevious }) => (
    isMobile ? <MobileView gotoPrevious={gotoPrevious} showBackButton={showBackButton} /> : 
    <DesktopView stepValue={stepValue} gotoPrevious ={gotoPrevious} showBackButton={showBackButton}/>
);

TaskLoaderNew.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  stepValue: PropTypes.oneOf(['general', 'education', 'domain', 'courses']).isRequired,
  gotoPrevious:PropTypes.func.isRequired
};

export default TaskLoaderNew;
