import apiClient from "./apiClient";
import {courseCategoriesApiEndpoint, courseCountApiEndpoint,courseLevelApiEndpoint} from "./index"


export const getCourseCount = async () => {
    try {
        const response = await apiClient.get(courseCountApiEndpoint);
        return response.data;
    } catch (error) {
        console.error("Error fetching course count:", error);
        throw error; // Re-throw error for handling in UI
    }
};


export const getCourseLevel = async (occupation) => {
    try {
        // Pass the query parameter as an object
        const response = await apiClient.get(courseLevelApiEndpoint, {
            params: {
                occupation: occupation, // Add query parameter dynamically
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching course level:", error.response?.data || error.message);
        throw error; // Re-throw error for handling in the calling code
    }
};

export const getCourseCategories = async (occupation, level) => {
    try {
        // Pass the query parameter as an object
        const response = await apiClient.get(courseCategoriesApiEndpoint, {
            params: {
                occupation: occupation, // Add query parameter dynamically
                level: level
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching course level:", error.response?.data || error.message);
        throw error; // Re-throw error for handling in the calling code
    }
};





