import React, { useState } from "react";
import { Box } from "@mui/material";
import DescriptionPage from "./DescriptionPage";
import LevelPage from "./LevelPage";
import CoursePage from "./CourseCategoryPage";
import CourseList from "./CourseList";

const HomePage = () => {
  const [page, setPage] = useState("description");


  const [generalSelection , setGeneralSelection] = useState('')
  const [educationSelection , setEducationSelection] = useState('')
  const [domainSelection , setDomainSelection] = useState('')

  const gotonext = (nextPage) => {
    setPage(nextPage); // Update to the desired page
  };

  const gotoPrevious = (previousPage) => {
    setPage(previousPage); // Update to the desired page
  }

  return (
    <Box >
        <Box style={{height: "100%"}}>
          {page === "description" && (
            <DescriptionPage
              setGeneralSelection={setGeneralSelection}
              gotoPrevious ={() => {}}
              gotonext={() => gotonext("level")} // Go to LevelPage
            />
          )}
          {page === "level" && (
            <LevelPage
              generalSelection = {generalSelection}
              setEducationSelection = {setEducationSelection}
              gotoPrevious ={() => gotoPrevious("description")}
              gotonext={() => gotonext("course")} // Go to CoursePage
            />
          )}
          {page === "course" && 
          <CoursePage 
             generalSelection = {generalSelection}
             educationSelection = {educationSelection}
             setDomainSelection = {setDomainSelection}
             gotoPrevious ={() => gotoPrevious("level")}
             gotonext={() => gotonext("course_list")} 
              />
              }

              {page === "course_list" && 
                <CourseList 
                generalSelection={generalSelection}
                educationSelection={educationSelection}
                domainSelection={domainSelection}
                gotoPrevious ={() => gotoPrevious("course")}
              />
              }

        </Box>
    </Box>
  );
};

export default HomePage;
