import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, useMediaQuery } from '@mui/material';
import {collegeStudent, employee, teacher, schoolStudent} from '../styles/staticImages'
import mediaQueries from '../styles/mediaQueries';
import TaskLoaderNew from '../components/TaskLoaderNew';
import { getCourseCount } from "../services/api";

const DescriptionPage = ({ gotonext,gotoPrevious,setGeneralSelection}) => {

    const isMobile = useMediaQuery(mediaQueries.xs); 


    const [selectedDiscription, setSelectedDiscription] = useState("none");
    const [courseCount, setCourseCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCourseCount = async () => {
            try {
                const data = await getCourseCount();
                setCourseCount(data.data.course_count);
            } catch (err) {
                setError(err.message || "Something went wrong!");
            } finally {
                setLoading(false);
            }
        };

        fetchCourseCount();
    }, []);

    const handleClick = (value) => {
        setGeneralSelection(value)
        setSelectedDiscription(value);
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <>
        <div style={{height: "80vh", position: "relative"}}>
        <Box sx={{ mt: 2, display:'flex' ,flexDirection: 'column', height: "100%"}}>
           {/* <TaskLoader isMobile={isMobile} gotoPrevious = {gotoPrevious} handleClose={handleClose} stepValue={"general"}/> */}
           <TaskLoaderNew isMobile={isMobile} showBackButton={false} gotoPrevious = {gotoPrevious} handleClose={() => {}} stepValue={"general"}/>
                {/* Title Section */}
                <Box sx={{ pl: { xs: 2, md: 10 },marginTop: isMobile ? '15px': '20px', marginLeft: '5px', justifyContent: 'left' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', color: 'rgba(78, 78, 78, 1)' }}>
                        <Typography
                            sx={{
                                fontSize: { xs: '24px', md: '32px' },
                                lineHeight: '48px',
                                fontWeight: '600',
                            }}
                            gutterBottom
                        >
                            SWAYAM has{' '}{courseCount}
                            <Typography
                                component="span"
                                sx={{
                                    fontSize: { xs: '24px', md: '32px' },
                                    fontWeight: '600',
                                    lineHeight : '48px',
                                    color: 'rgba(17, 58, 130, 1)',
                                }}
                            > courses
                            </Typography>!
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: { xs: '16px', md: '20px' },
                                lineHeight: '30px',
                                fontWeight: '400',
                            }}
                            gutterBottom
                        >
                            Choose what best describes you:
                        </Typography>
                    </Box>
                </Box>

                {/* Selection Boxes */}
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 2,
                    }}
                >
                    {[
                        { label: 'Working Professional', value: 'employee', img: employee, borderColor: 'rgba(177, 157, 238, 1)' },
                        { label: 'Teacher', value: 'teacher', img: teacher, borderColor: 'rgba(146, 227, 169, 1)' },
                        { label: 'College Student', value: 'college_student', img: collegeStudent, borderColor: 'rgba(255, 199, 39, 1)' },
                        { label: 'School Student', value: 'school_student', img: schoolStudent, borderColor: 'rgba(255, 114, 94, 1)' },
                    ].map((item) => (
                        <Box
                            key={item.value}
                            onClick={() => handleClick(item.value)}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius:'24px',
                                alignItems: 'center',
                                p: 2,
                                cursor: 'pointer',
                                transition: 'all 0.3s ease',
                                '&:hover': { boxShadow: 0, transform: 'translateY(-2px)' },
                            }}
                        >
                            <Box
                                sx={{
                                    width: isMobile ? 120 : "25vh",
                                    height: isMobile ? 120 : "25vh",
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border:
                                        selectedDiscription === item.value
                                            ? `2px solid ${item.borderColor}`
                                            : '2px solid transparent',
                                    transition: 'all 0.3s ease',
                                }}
                            >
                                <img
                                    src={item.img}
                                    alt={item.label}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            </Box>
                            <Typography
                                fontWeight={isMobile? 400 : 500 }
                                fontSize={ isMobile ? 14  :  18}
                                sx={{
                                    color:
                                        selectedDiscription === item.value
                                            ? 'rgba(17, 58, 130, 1)'
                                            : 'rgba(176, 176, 176, 1)',
                                    mt: 1,
                                }}
                            >
                                {item.label}
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    mr: { xs: 2, md: 4 },
                    // marginBottom: isMobile ? '33px' : '',
                    // marginRight:  isMobile ? '18px' : '80px'
                }}
                >
                
            </Box>
            </Box>

            {/* Next Button */}
            <Button
                    variant="contained"
                    onClick={()=>gotonext("level")}
                    disabled={selectedDiscription === 'none'}
                    sx={{
                        position: "absolute",
                        right: "30px",
                        bottom: "20px",
                        borderRadius: '16px',
                        width: isMobile? '156px' : { xs: '120px', md: '13vh' },
                        height: isMobile? '56px' : { xs: '50px', md: '8vh' },
                        fontSize: { xs: '18px', md: '24px' },
                        fontWeight: '600',
                        lineHeight: '36px',
                        backgroundColor: 'rgba(17, 58, 130, 1)',
                        color: '#FFF',
                        fontFamily: "'Poppins', sans-serif",
                        '&.Mui-disabled': {
                            backgroundColor: '#C6C6C6',
                        }
                    }}
                >
                    Next
            </Button>
            
            </div>
            </>
    );
};

export default DescriptionPage;
