import React from 'react';
import { IconButton } from '@mui/material';
import {circularImg} from '../styles/staticImages'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const BackButton = ({ isMobile, onClick}) => {
    return (
        <IconButton 
      onClick={onClick} 
      sx={{ 
        position: 'relative', 
        width: 56, 
        height: 56, 
        p: 0, 
        display: 'flex',   
        justifyContent: 'center', 
        alignItems: 'center', 
      }}
    >
      <img 
        src={circularImg} 
        alt="Circular" 
        style={{ position: 'absolute', width: '100%', height: '100%', opacity: isMobile ? '0' : '1' }} 
      />
      
      <ArrowBackIcon 
        sx={{ width: isMobile? 24: 28, height: isMobile? 24: 28 ,position:'absolute', color:'#113A82'}} 
      />
    </IconButton>
    )
};

export default BackButton;
