// Define the standard Material UI breakpoints for consistency
const breakpoints = {
    xs: 0,      // Extra small devices
    sm: 600,    // Small devices (tablets)
    md: 960,    // Medium devices (desktops)
    lg: 1280,   // Large devices (larger desktops)
    xl: 1920,   // Extra-large devices
  };
  
  // Media Query Utility
  const mediaQueries = {
    xs: `@media (max-width: ${breakpoints.sm - 1}px)`,  // Mobile-first
    sm: `@media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.md - 1}px)`,
    md: `@media (min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg - 1}px)`,
    lg: `@media (min-width: ${breakpoints.lg}px) and (max-width: ${breakpoints.xl - 1}px)`,
    xl: `@media (min-width: ${breakpoints.xl}px)`,
  };
  
  export default mediaQueries;
  