import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/Homepage';

function loadFont() {
  const link = document.createElement('link');
  link.href = "https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap";
  link.rel = "stylesheet";
  document.head.appendChild(link);
}


function AppRoutes() {
  React.useEffect(() => {
    loadFont();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/*" element={<HomePage/>} />
      </Routes>
    </Router>
  )
}

export default AppRoutes;
