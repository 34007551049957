const baseUrl = "https://learn-staging.wadhwaniai.org/static/media/images/"


function f(value){
    return baseUrl + value
}

export const backgroundComplete = f("background-complete.svg")
export const domainTask = f("domain_task.svg")
export const backgroundImage = f("background_image.svg")
export const educationTask = f("education_task.svg")
export const bgImage = f("bgImage.svg")
export const employee = f("employee.svg")
export const certificatePersonImg = f("certificate_person_img.svg")
export const generalTask = f("general_task.svg")
export const certification = f("certification.svg")
export const graduationHat = f("graduation_hat.svg")
export const circularImg = f("circular_img.svg")
export const levelCharacter = f("level_character.svg")
export const collegeStudent = f("college-student.svg")
export const rightArrow = f("right_arrow.svg")
export const continueEducation = f("continue_education.svg")
export const schoolStudent = f("school-student.svg")
export const coursesTask = f("courses_task.svg")
export const teacher = f("teacher.svg")
export const crossImg = f("cross_img.svg")