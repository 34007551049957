import qs from "query-string"
import React from 'react';
import { Typography, Grid} from '@mui/material';
import Button from '@mui/material/Button';
// import backgroundSvg from "../static/background-complete.svg"
import IconButton from '@mui/material/IconButton';
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LanguageIcon from '@mui/icons-material/Language';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import Divider from '@mui/material/Divider';
import TaskLoaderNew from '../components/TaskLoaderNew';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { courseListAPIEndpoint, logClickAPIEndpoint } from "../services";


const globalColors = {
    headlineText1: "#4E4E4E",
    headlineText2: "#444444",
    headlineText3: "#606060",
    headlineTextMobile1: "#407BFF",
    headlineTextMobile2: "#858585",
    headlineTextMobile3: "#606060",
    themeColorBlue: "#113A82",
    themeColorRed: "#FF725E",
    borderColor1: "#D6DBF2",
    enrollmentStatusOpen: "#44A04D",
    enrollmentStatusClosed: "#AA4141",
}


function CourseList({generalSelection, educationSelection, domainSelection, gotoPrevious}){
    
    const ListOfKeywords = [generalSelection, educationSelection, domainSelection]
    const isMobile = useBreakpoint() === "sm";

    return (
        <>
        <div>
            <div style={{marginTop: isMobile ? "0px": "30px"}}>
                <TaskLoaderNew isMobile={isMobile} showBackButton={true}  gotoPrevious={gotoPrevious} stepValue={"courses"}/>
            </div>

            {
                isMobile ? 
                MobileView(ListOfKeywords)
                :
                DesktopView(ListOfKeywords)
            }
        </div>

        </>
    )
}

function DesktopView(listOfKeywords){
    const [courseCount, setCourseCount] = React.useState();
    const [courseContent, setCourseContent] = React.useState()
    const [loader, setLoader] = React.useState()
    const [showReset, setShowReset] = React.useState(false)
    const [searchText, setSearchText] = React.useState("");
    const [xRequestID, setxRequestId] = React.useState();
    
    
    React.useEffect(() => {
        if(searchText !== "")
            setShowReset(true)
        else
            setShowReset(false)
    }, [searchText])


    async function fetchCount(keyword=null) {
        try {
            setLoader(true)
            const baseUrl = process.env.REACT_APP_API_BASE_URL + courseListAPIEndpoint;
            const url = qs.stringifyUrl({ url: baseUrl, query: {occupation: listOfKeywords[0], level: listOfKeywords[1], category: listOfKeywords[2], ...(keyword !== null && {keyword: keyword})} });
            const response = await fetch(url);
            console.log("responseo ", response)
            const responseRequestId = response.headers.get("X-Request-Id");
            console.log("request id", responseRequestId)
            setxRequestId(responseRequestId);
            const data = await response.json();
            setCourseContent(data.data.course_list); 
            setCourseCount(data.data.course_count)
        } catch (error) {
            console.error("Error fetching count:", error);
            setCourseContent(null)
        } finally {
            setLoader(false)
        }
    };
    
    function clickViewResult(courseId, courseUrl){
        const baseUrl = process.env.REACT_APP_API_BASE_URL + logClickAPIEndpoint; 
        const url = qs.stringifyUrl({url: baseUrl , query: {course_id: courseId}})
        
        const headers = new Headers({
            "x-request-id": xRequestID,
            "Content-Type": "application/json"
        });

        fetch(url, {method: "GET", headers: headers}).then((res) => {
            console.log("logged data", res)
        })
        window.open(courseUrl, "_blank");
    }

    
    React.useEffect(() => {
          fetchCount();
        // eslint-disable-next-line
    }, [])

    return (
    <>
        <div style={{ justifyContent: "center", background: "white"}}>
            <div style={{width: "100%", backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/static/media/images/background-complete.svg)`, backgroundPosition: 'top right',backgroundSize: 'contain', backgroundRepeat: 'no-repeat', display: "flex", flexDirection: "row"}}>
                <div style={{marginLeft: "50px", width: "80%", display: "flex", flexDirection: "column"}}>
                    <Typography style={{fontSize: "32px", fontFamily: "'Poppins', sans-serif", fontWeight: "600", color: globalColors.headlineText1}}>
                        Search courses you <span style={{color: globalColors.themeColorBlue}}>love</span>!
                    </Typography>
                    <Typography style={{marginTop: "25px", fontSize: "20px", fontFamily: "'Poppins', sans-serif", fontWeight: "400", color: globalColors.headlineText2}}>
                        {courseCount === 0 ? "No courses found": `${courseCount} courses just for you:`}
                    </Typography>
                    
                    {SearchBar(listOfKeywords, fetchCount, searchText, setSearchText)}
                    
                    <div style={{ height: "500px", marginTop: "20px", overflowY: "auto", display: "flex", flexDirection: "column"}}>
                        <div>
                            <Grid container>
                                {RenderCourseContent(courseContent, loader, clickViewResult)}
                            </Grid>
                            <div style={{height: "300px"}}></div>
                        </div>
                    
                    </div>
                </div>
                
                {
                    showReset &&
                    <div style={{marginTop: "135px", marginLeft: "20px"}}>
                        <Button style={{textTransform: "none"}} onClick={() => {
                            fetchCount(null)
                            setSearchText("")
                        }}>
                            <Typography style={{fontSize: "16px", fontFamily: "'Poppins', sans-serif", fontWeight: "500", color: globalColors.themeColorRed}}>
                                Reset
                            </Typography>
                        </Button>
                    </div>
                }
            
            </div>
        </div>
    </>
    )
}

function MobileView(listOfKeywords){

    const [courseCount, setCourseCount] = React.useState(1234)
    const [courseContent, setCourseContent] = React.useState()
    const [loader, setLoader] = React.useState()
    const [searchText, setSearchText] = React.useState("");
    const [xRequestID, setxRequestId] = React.useState();
    
    async function fetchCount(keyword=null){
        try {
            setLoader(true)
            const baseUrl = process.env.REACT_APP_API_BASE_URL + courseListAPIEndpoint; 
            const url = qs.stringifyUrl({ url: baseUrl, query: {occupation: listOfKeywords[0], level: listOfKeywords[1], category: listOfKeywords[2], ...(keyword !== null && {keyword: keyword}) } });
            const response = await fetch(url);
            const responseRequestId = response.headers.get("x-request-id");
            setxRequestId(responseRequestId);
            const data = await response.json();
            setCourseContent(data.data.course_list); 
            setCourseCount(data.data.course_count)
        } catch (error) {
            console.error("Error fetching count:", error);
            setCourseContent(null)
        } finally {
            setLoader(false)
        }
    };

    function clickViewResult(courseId, courseUrl){
        const baseUrl = process.env.REACT_APP_API_BASE_URL + logClickAPIEndpoint; 
        const url = qs.stringifyUrl({url: baseUrl , query: {course_id: courseId}})
        
        const headers = new Headers({
            "x-request-id": xRequestID,
            "Content-Type": "application/json"
        });

        fetch(url, {method: "GET", headers: headers})
        window.open(courseUrl, "_blank");
    }

    React.useEffect(() => {
          fetchCount();
        // eslint-disable-next-line
    }, [])


    return (
        <>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "15px", width: "100%" }}>
                <div style={{display: "flex", width: "95%", flexDirection: "column"}}>
                    <Typography style={{fontSize: "20px", fontFamily: "'Poppins', sans-serif", fontWeight: "600", color: globalColors.headlineText1}}>
                        Search courses you <span style={{color: globalColors.themeColorBlue}}>love</span>!
                    </Typography>
                    <Typography style={{marginTop: "10px", fontSize: "16px", fontFamily: "'Poppins', sans-serif", fontWeight: "400", color: globalColors.headlineText2}}>
                        {courseCount} courses just for you:
                    </Typography>
                    
                    {SearchBar(listOfKeywords, fetchCount, searchText, setSearchText)}
                    
                    <div style={{  marginTop: "20px", overflowY: "auto", display: "flex", flexDirection: "column"}}>
                        <div>
                            <Grid container>
                                {RenderCourseContent(courseContent, loader, clickViewResult)}
                            </Grid>
                            <div style={{height: "300px"}}></div>
                        </div>
                    
                    </div>
                </div>
            </div>
        </>
        )

}



function SearchBar(listOfKeywords, fetchCount, searchText, setSearchText){
    const isMobile = useBreakpoint() === "sm";

    const handleChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleSearch = () => {
        if(searchText === "")
            fetchCount(null);
        else
            fetchCount(searchText);
    };
    return (
        <TextField
                sx={{marginTop: "10px", background: "white", '& .MuiOutlinedInput-root': {padding: isMobile ? "0px": "8px"}}}
                variant="outlined"
                placeholder={isMobile? "Type here" : "Type to add more keywords"}
                value={searchText}
                onChange={handleChange}
                onKeyPress={(event) => {
                    if (event.key === "Enter") {
                    handleSearch();
                    }
                }}
                InputProps={{
                    style: {
                        borderRadius: "16px",
                        fontSize: isMobile ? "14px": "16px",
                        fontWeight: "400"
                    },
                    startAdornment: (
                    <InputAdornment position="start">
                        <RenderKeywords listOfKeywords={ isMobile ? listOfKeywords.slice(1): listOfKeywords}/>
                    </InputAdornment>
                        
                    ),
                    endAdornment: (
                    <InputAdornment position="end">
                    <div style={{display: "flex", flexDirection: "row"}}>
                    <Divider orientation="vertical" flexItem sx={{ borderWidth: "1px", borderColor: "#D1D4E2"}} />
                    <IconButton onClick={handleSearch}>
                        <SearchIcon sx={{fontSize: isMobile ? "20px": "26px"}}/>
                    </IconButton>
                    </div>
                    </InputAdornment>
                    ),
                }}
            />
    )
}

function RenderKeywords({listOfKeywords}){
    const keywordMapping = {
        'employee': 'Working',
        'teacher': 'Teacher',
        'college_student': 'College',
        'school_student': 'School',
        "Undergraduate": "UG",
        "Postgraduate": "PG",
        "Diploma": "Dip",
        "Certificate": "Cert",
        "Continuing": "CE",
    }
    const isMobile = useBreakpoint() === "sm";

    function formatKeyword(keyword){
        if (keyword.length > 10) {
            return keyword.substring(0, 10) + "...";
        }
        return keyword;
    }

    return (
        <>
        <div style={{display: "flex", padding: "10px"}}>
            {    
                listOfKeywords.map( keyword => {
                    return (
                        <div style={{background: "#EEF1FF", padding: "10px", marginRight: "10px", borderRadius: "10px"}} key={keyword}>
                            <Typography style={{fontSize: isMobile ? "12px": "16px", fontWeight: "500", fontFamily: "'Poppins', sans-serif", color: globalColors.themeColorBlue}}>
                                { keywordMapping[keyword] ? keywordMapping[keyword]: formatKeyword(keyword)}
                            </Typography>
                        </div>
                    )
                })
            }
        </div>
        </>
    )
}

function RenderCourseContent(courseContent, loader, clickViewResult){
    // const isMobile = useBreakpoint() === "sm";
    const breakpoint = useBreakpoint()
    const isMobile = breakpoint === "sm"

    function formatName(courseName) {
        if (courseName.length > 50) {
            return courseName.substring(0, 40) + "...";
        }
        return courseName;
    }

    function formatInstructorName(instructor, instructorInstitute){
        let combinedString = `${instructor} | ${instructorInstitute}`
        if (breakpoint === "md" && combinedString.length > 70) {
            return combinedString.substring(0, 70) + "...";
        }
        return combinedString;
    }

    function courseStatus(endDate, selfPaced){
        if(selfPaced)
            return "Self Paced"
        else{
            const courseEndDate = new Date(endDate);
            const currentDate = new Date();
            if(currentDate <= courseEndDate)
                return "Open"
            else
                return "Closed"
        }
    }

    function statusColor(endDate, selfPaced){
        if(selfPaced)
            return globalColors.headlineText3
        else{
            const courseEndDate = new Date(endDate);
            const currentDate = new Date();
            if(currentDate <= courseEndDate)
                return globalColors.enrollmentStatusOpen
            else
                return globalColors.themeColorRed
        }
    }

    function encodeUrl(url) {
        if (decodeURIComponent(url) !== url) {
            return url;
        }
        return encodeURI(url);
    }

    return (
        <>
        {
            loader ?
            <div style={{width: "100%", height: "400px", display: "flex", justifyContent: "center", alignItems: "center"}}> 
                <CircularProgress size={isMobile ? 60: 80} sx={{color: globalColors.themeColorBlue}}/>
            </div>
            :
            courseContent && courseContent.length > 0 ?
            courseContent.map((course, index) => {
            return (
                isMobile ?
                // Mobile View
                <Grid item xs={12} key={course.course_title + index}>
                    <div style={{ minHeight: "150px", margin: "8px", border: `1px solid ${globalColors.borderColor1}`, borderRadius: "8px", overflow: "hidden", backgroundColor: "white", display: "flex", flexDirection: "column"}} >
                        <Grid container style={{margin: "10px"}} >
                            <Grid item xs={3} >
                                <div style={{width: "100%", height: "85px", background: "black", borderRadius: "8px",}}>
                                <img src={encodeUrl(course.course_picture_url)} alt={course.course_title} style={{height: "100%", width: "100%",  borderRadius: "8px"}}/>
                                </div>
                            </Grid>
                            <Grid item xs={8} style={{marginLeft: "10px"}} >
                                <Typography sx={{fontSize: "16px", fontFamily: "'Poppins', sans-serif", fontWeight: "500", color: globalColors.headlineTextMobile1}} 
                                onClick={() => {
                                    clickViewResult( course.course_id, course.course_url)
                                }}>
                                    {formatName(course.course_title)}
                                </Typography>

                                <div style={{display: "flex", alignItems: "center", marginTop: "3px"}}>
                                    <Typography style={{marginLeft: "5px", fontSize: "14px", fontFamily: "'Poppins', sans-serif", fontWeight: "400", color: globalColors.headlineTextMobile2}}>
                                        by {course.instructor_name}
                                    </Typography>
                                </div>

                                <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginTop: "3px"}}>
                                    <LanguageIcon sx={{ fontSize: "14px", color: globalColors.headlineTextMobile2}}/>
                                    <Typography style={{marginLeft: "3px", fontSize: "14px", fontFamily: "'Poppins', sans-serif", fontWeight: "400", color: globalColors.headlineTextMobile2}}>
                                        {course.national_coordinator}
                                    </Typography>
                                    
                                    {
                                        !course.self_paced  &&
                                        <>
                                        <QueryBuilderIcon sx={{ marginLeft: "10px", fontSize: "14px", color: globalColors.headlineTextMobile2}}/>
                                        <Typography style={{marginLeft: "3px", fontSize: "14px", fontFamily: "'Poppins', sans-serif", fontWeight: "400", color: globalColors.headlineTextMobile2}}>
                                            {course.duration}w
                                        </Typography>
                                        </>
                                    }

                                    <AccountCircleIcon sx={{ marginLeft: "10px", fontSize: "14px", color: globalColors.headlineTextMobile2}}/>
                                    <Typography style={{marginLeft: "3px", fontSize: "14px", fontFamily: "'Poppins', sans-serif", fontWeight: "400", color: statusColor(course.enrollment_end_date, course.self_paced)}}>
                                        {courseStatus(course.enrollment_end_date, course.self_paced)}
                                    </Typography>

                                </div>
                                <div style={{display: "flex", alignItems: "center", marginTop: "3px"}}>
                                    <Typography style={{fontSize: "12px", fontFamily: "'Poppins', sans-serif", fontWeight: "400", color: globalColors.headlineTextMobile3}}>
                                            {course.instructor_institute}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>

                    </div>
                </Grid>
                :
                // Desktop View
                <Grid item xs={12} md={4} xl={3} key={course.course_title + index}>
                    <div style={{ height: "450px", margin: "20px", border: "1px solid #ccc", borderRadius: "8px", overflow: "hidden", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", backgroundColor: "white"}} >
                        <div style={{width: "100%", height: "30%", background: "black"}}>
                        <img src={encodeUrl(course.course_picture_url)} alt={course.course_title} style={{height: "100%", width: "100%"}}/>
                        </div>
                    <div style={{margin: "10px", height: "60%", display: "flex", flexDirection: "column"}}>

                        <div style={{flex: 1}}>
                            <Typography style={{fontSize: "18px", fontFamily: "'Poppins', sans-serif", fontWeight: "600", color: globalColors.headlineText2}}>
                                {formatName(course.course_title)}
                            </Typography>

                            
                                <div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
                                    <LanguageIcon sx={{ fontSize: "14px", color: globalColors.headlineText2}}/>
                                    <Typography style={{marginLeft: "5px", fontFamily: "'Poppins', sans-serif", fontSize: "14px", fontWeight: "400", color: globalColors.headlineText2}}>
                                        {course.national_coordinator}
                                    </Typography>
                                    {
                                        !course.self_paced  &&
                                        <>
                                        <QueryBuilderIcon sx={{ marginLeft: "10px", fontSize: "14px", color: globalColors.headlineTextMobile2}}/>
                                        <Typography style={{marginLeft: "3px", fontSize: "14px", fontFamily: "'Poppins', sans-serif", fontWeight: "400", color: globalColors.headlineTextMobile2}}>
                                            {course.duration}w
                                        </Typography>
                                        </>
                                    }
                                    <AccountCircleIcon sx={{ marginLeft: "10px", fontSize: "16px", color: globalColors.headlineTextMobile2}}/>
                                    <Typography style={{marginLeft: "3px", fontSize: "14px", fontFamily: "'Poppins', sans-serif", fontWeight: "400", color: statusColor(course.enrollment_end_date, course.self_paced)}}>
                                        {courseStatus(course.enrollment_end_date, course.self_paced)}
                                    </Typography>
                                </div>

                                <Typography style={{marginTop: "5px", fontSize: "14px", fontFamily: "'Poppins', sans-serif", fontWeight: "400", color: globalColors.headlineText3}}>
                                    {formatInstructorName(course.instructor_name, course.instructor_institute)}
                                </Typography>
                        </div>

                        <Button 
                        variant="contained" 
                        sx={{background: globalColors.themeColorBlue, height: "65px", borderRadius: "16px"}} 
                        onClick={() => {
                            clickViewResult( course.course_id, course.course_url)
                        }}>
                            <Typography style={{marginTop: "5px", fontSize: "24px", fontFamily: "'Poppins', sans-serif", fontWeight: "600", color: "white"}}>
                                View Course
                            </Typography>
                        </Button>
                        </div>

                    </div>
                </Grid>
            )
            })
        :
        // no result found for keyword 
        <div style={{height: "200px", display: "flex", alignItems: "center", justifyContent: "center", padding: "30px"}}>
            <Typography style={{marginTop: "5px", fontSize: isMobile? "16px": "24px", fontFamily: "'Poppins', sans-serif", fontWeight: "600", textAlign: 'center'}}>
                We couldn't find any matching courses. Try adjusting your keyword or browsing other categories!
            </Typography>
        </div>
            
        }
    </>
    )
}




function useBreakpoint(){
    const theme = useTheme()
    const md = useMediaQuery(theme.breakpoints.up('md'))
    const lg = useMediaQuery(theme.breakpoints.up('lg'))
    const xl = useMediaQuery(theme.breakpoints.up('xl'))
    return xl ? 'xl' : lg ? 'lg' : md ? 'md': 'sm'
}




export default CourseList;